import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from "react-i18next";
import i18n from '../i18n';
import ReactLoading from 'react-loading';
import { AiOutlineMinusCircle } from "react-icons/ai";
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { readFile, utils } from 'xlsx';
//context
import UserContext from '../context/UserContext';
//api
import aws from '../api/aws';
//components
import { NumberKgCO2e } from '../components/KgCO2e';
import { ButtonFull, ButtonEmpty } from '../components/Buttons';
import { ButtonOption, RadioOption } from '../components/ToolBarOption';
import { OrderArrows } from '../components/OrderArrows';
import { useInterval } from '../components/useInterval';
//util
import { colors, colorValues, completeUnits2, massUnits, volumesUnits } from '../util/values';
import { commonStyles } from '../util/styles';
import { sortBy, getColor, getMonthDate, calculateCo2FromYield, getUnit, toLowerNoAccents, UTCToLocal2, getIng } from '../util/climatecook';
let oi = 0;
const PurchasesDataScreen = () => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const [items, setItems] = useState([]);
  const [visibleItems, setVisibleItems] = useState([]);
  const [search, setSearch] = useState("");
  const [orderType, setOrderType] = useState("dateNull");
  const [isOrderAsc, setIsOrderAsc] = useState(true);
  const [error1, setError1] = useState('');
  const [error2, setError2] = useState('');
  const [error3, setError3] = useState('');
  const [error4, setError4] = useState('');
  const [error5, setError5] = useState('');
  const [error6, setError6] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showModalImportSuccess, setShowModalImportSuccess] = useState(false);
  const [showModalImport, setShowModalImport] = useState(false);
  const [showModalLength, setShowModalLength] = useState(false);
  const [deleteIds, setDeleteIds] = useState([]);
  const [deleteId, setDeleteId] = useState(-1);
  const [hover, setHover] = useState("");
  const [isReady, setIsReady] = useState(false);
  const [clickPosition, setClickPosition] = useState(-1);
  const [errorArray1, setErrorArray1] = useState([]);
  const [errorArray2, setErrorArray2] = useState([]);
  const [errorArray3, setErrorArray3] = useState([]);
  const [errorArray4, setErrorArray4] = useState([]);
  const [errorArray5, setErrorArray5] = useState([]);
  const [errorArray6, setErrorArray6] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [progress, setProgress] = useState(0);
  const [step, setStep] = useState(0);
  //let progress = 0;
  const firstUpdate = useRef(true);
  let timer;

  const handleMouseEnter = (which) => {
    setHover(which);
  };

  const handleMouseLeave = () => {
    setHover("");
  };

  useEffect(() => {
    getIngredients();
  }, []);

  useEffect(() => {
    getSales(true);
  }, [ingredients]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    showItems();
  }, [isOrderAsc, orderType]);

  useInterval(
    () => {
      if (showModalImportSuccess || progress === 100) {
        setShowModalImportSuccess(true);
        getSales(true);
        setShowModalImport(false);
        setProgress(0);
      } else {
        setProgress((oldProgress) => Math.min(oldProgress + step, 100));
      }
    },
    showModalImport ? 500 : null
  );

  const getIngredients = async () => {
    const response = await aws.get('/ingredients/composition', { params: { isRecipe: 100, owner: user[0].user_id, isNullCategories: true, lang: i18n.language, region: 'CA' } });
    if (response.data) {
      let newFullIngredients = response.data.res;
      for (let i = 0; i < newFullIngredients.length; i++) {
        if (newFullIngredients[i].ing_level === 0) {
          newFullIngredients[i].thresholdHigh = colorValues.ingredientHigh;
          newFullIngredients[i].thresholdLow = colorValues.ingredientLow;
          //let co2 = 0;
          //if (newFullIngredients[i].co2 === null) {
          //  co2 = getIngredientCo2(newFullIngredients, newFullIngredients[i]);
          //} else {
          //  co2 = newFullIngredients[i].co2;
          //}
          //newIngredients.push({ ...newFullIngredients[i], co2 });
          //newFullIngredients[i].co2 = co2;
        }
      }
      let newIngredients = [];
      for (let i = 0; i < newFullIngredients.length; i++) {
        if (newFullIngredients[i].ing_level === 0) {
          newIngredients.push(getIng(newFullIngredients, newFullIngredients[i], false));
        }
      }
      setIngredients(newIngredients);
    }
  };

  /*const getIngredientCo2 = (itemList, mainItem) => {
    let totalCo2 = 0;
    for (let i = 0; i < itemList.length; i++) {
      if (itemList[i].main_ingredient_id === mainItem.ingredient_id) {
        if (itemList[i].co2 !== null) {
          totalCo2 += calculateCo2FromYield([itemList[i]]);
        } else if (itemList[i].co2 === null) {
          let co2 = getIngredientCo2(itemList, itemList[i]);
          itemList[i].co2 = co2;
          totalCo2 += calculateCo2FromYield([itemList[i]]);
        }
      }
    }
    return totalCo2;
  };*/

  const getSales = async (bypass = false) => {
    if (!bypass || ingredients.length === 0) {
      return;
    }
    setIsReady(false);
    const response = await aws.get('/stats/purchases', { params: { owner: user[0].user_id, dateFrom: '2010-01-01', dateTo: '2050-01-01' } });
    if (response.data) {
      /*let dups = [];
      for (let i = 0; i < response.data.length; i++) {
        if (response.data[i].ing_level !== 1 && dups.some(o => o.main_ingredient_id === response.data[i].main_ingredient_id && o.ingredient_id === response.data[i].ingredient_id)) {
          response.data[i] = { remove: true };
        } else if (response.data[i].ing_level !== 1) {
          dups.push({ main_ingredient_id: response.data[i].main_ingredient_id, ingredient_id: response.data[i].ingredient_id });
        }
      }
      response.data = response.data.filter(o => !o.remove);*/

      let newIngredients = [];
      for (let i = 0; i < response.data.length; i++) {
        newIngredients.push({
          id: response.data[i].id,
          purchase_name: response.data[i].name,
          purchase_quantity: response.data[i].quantity,
          purchase_unit: response.data[i].unit,
          date: (response.data[i].date != null ? response.data[i].date : null),
          ...ingredients.find(o => o.main_ingredient_id === null && response.data[i].ingredient_id === o.ingredient_id)
        });
      }

      setItems(newIngredients);
      newIngredients = setOrder(isOrderAsc, newIngredients);
      setVisibleItems(newIngredients);
      setIngredients([]);
    }
    setIsReady(true);
  };

  const onEnterPressed = (key) => {
    if (key === 'Enter') {
      showItems();
    }
  };

  const toggleOrder = (type) => {
    setIsOrderAsc(!isOrderAsc);
    setOrderType(type);
  };

  const showItems = () => {
    let newItems = [];
    for (let i = 0; i < items.length; i++) {
      let name = i18n.language === 'fr' ? items[i].name_fra : items[i].name_eng;
      name = toLowerNoAccents(name);
      let searchInput = toLowerNoAccents(search);
      if (name.includes(searchInput)) {
        newItems.push(items[i]);
      }
    }

    newItems = setOrder(isOrderAsc, newItems);
    setVisibleItems(newItems);
    setIsReady(true);
  };

  const setOrder = (order, itemList) => {
    let newRecipes = [];
    if (orderType === 'name') {
      newRecipes = itemList.sort(sortBy(i18n.language === 'fr' ? 'name_fra' : 'name_eng', order));
    } else if (orderType === 'portions') {
      newRecipes = itemList.sort(sortBy('total_portions', order, false, Number));
    } else if (orderType === 'date') {
      newRecipes = itemList.sort(sortBy('date', order));
    } else if (orderType === 'dateNull') {
      newRecipes = itemList.sort(sortBy('dateNull', order));
    }
    return newRecipes;
  };

  const onImport = async (url) => {
    setShowModalImport(true);
    setErrorArray1([]);
    setErrorArray2([]);
    setErrorArray3([]);
    setErrorArray4([]);
    setErrorArray5([]);
    setErrorArray6([]);
    if (url != null) {
      const file = await (await fetch(URL.createObjectURL(url))).arrayBuffer();
      const workbook2 = readFile(file, { cellDates: true });
      const first_sheet2 = workbook2.Sheets[workbook2.SheetNames[0]];
      /*console.log(first_sheet2);
      let stop = false;
      let index = first_sheet2['B1'].v;
      for(let i = 0; stop; i++) {
        let field = first_sheet2['B' + i];
        rows[i].quantity.indexOf(',');
      }*/
      const rows = utils.sheet_to_json(first_sheet2, { header: ['name', 'quantity', 'unit', 'date'] });
      if (isNaN(rows[0].quantity)) {
        rows.splice(0, 1);
      }
      if (rows.length > 30000) {
        setShowModalImport(false);
        setShowModalLength(true);
        document.getElementById("uploadSalesButton").value = "";
        return;
      }
      let dateErrors = [];
      let unitErrors = [];
      let yieldErrors = [];
      let quantityErrors = [];
      let nameErrors = [];
      for (let i = 0; i < rows.length; i++) {
        let date = new Date(rows[i].date);
        if (date instanceof Date && !isNaN(date)) {
          rows[i].date = getMonthDate(date);
        } else {
          dateErrors.push(rows[i].name);
        }
        let unit = getUnit(rows[i].unit);
        if (unit) {
          rows[i].unit = unit;
        } else {
          unitErrors.push(rows[i].name);
        }
        if (isNaN(rows[i].quantity)) {
          quantityErrors.push(rows[i].name);
        }
      }

      //check for duplicates
      const allPurchases = await aws.get('/stats/purchases', { params: { which: 'duplicate', owner: user[0].user_id } });
      let duplicateErrors = [];
      rows.forEach(r => {
        if (allPurchases.data.some(p => toLowerNoAccents(p.name) === toLowerNoAccents(r.name) && p.quantity === r.quantity && p.unit === r.unit && UTCToLocal2(p.date).getTime() === r.date)) {
          duplicateErrors.push(r.name);
        }
      });

      const allIngs = await aws.get('/ingredients', { params: { type: 'yields', owner: user[0].user_id, lang: i18n.language } });

      let newRows = [];
      for (let r of rows) {
        let exists = false;
        for (let nr of newRows) {
          if (r.name === nr.name && r.date === nr.date && r.unit === nr.unit) {
            exists = true;
            nr.quantity += r.quantity;
            break;
          }
        }
        if (!exists) {
          let exists2 = false;
          for (let ing of allIngs.data) {
            if (toLowerNoAccents(r.name) === (i18n.language === 'fr' ? toLowerNoAccents(ing.name_fra) : toLowerNoAccents(ing.name_eng))) {
              exists2 = true;
              if ((massUnits[r.unit] && ing.yield_kg === null) || (volumesUnits[r.unit] && ing.yield_l === null) || (r.unit === 'unit' && ing.yield_unit === null)) {
                yieldErrors.push(r.name);
              }
              break;
            }
          }
          if (!exists2) {
            nameErrors.push(r.name);
          }
          newRows.push({ id: r.id, name: r.name, date: r.date, quantity: r.quantity, unit: r.unit });
        }
      }

      if (nameErrors.length > 0) {
        setErrorArray1(nameErrors);
        setError1('lblUnknownName');
      }
      if (dateErrors.length > 0) {
        setError2('lblUnknownDate');
        setErrorArray2(dateErrors);
      }
      if (unitErrors.length > 0) {
        setError3('lblUnknownUnit');
        setErrorArray3(unitErrors);
      }
      if (quantityErrors.length > 0) {
        setError4('lblUnknownQuantity');
        setErrorArray4(quantityErrors);
      }
      if (yieldErrors.length > 0) {
        setErrorArray5(yieldErrors);
        setError5('lblUnknownYield');
      }
      if (duplicateErrors.length > 0) {
        setErrorArray6(duplicateErrors);
        setError6('lblUnknownDuplicate');
      }
      if (nameErrors.length > 0 || dateErrors.length > 0 || unitErrors.length > 0 || quantityErrors.length > 0 || yieldErrors.length > 0 || duplicateErrors.length > 0) {
        setShowModalImport(false);
        setShowModal(true);
        document.getElementById("uploadSalesButton").value = "";
        return;
      }

      let totalSec = (0.11 * newRows.length) + 10;
      totalSec = totalSec > 900 ? 900 : totalSec;
      setStep(100 / (2 * totalSec));

      let response = await aws.post('/stats/purchases', { owner: user[0].user_id, data: newRows });
      if (response.data) {
        setShowModalImportSuccess(true);
        getSales(true);
        setShowModalImport(false);
        setProgress(0);
      }
      document.getElementById("uploadSalesButton").value = "";
    }
  };

  const onSelect = (e, id) => {
    let inBetweenIds = [];
    let newDeleteIds = [...deleteIds];
    if (!e.shiftKey) {
      setClickPosition(id);
      inBetweenIds.push(id);
    } else if (clickPosition !== -1) {
      let add = false;
      for (let i = 0; i < visibleItems.length; i++) {
        if (clickPosition === visibleItems[i].id || id === visibleItems[i].id) {
          add = !add;
          inBetweenIds.push(visibleItems[i].id);
        } else if (add) {
          inBetweenIds.push(visibleItems[i].id);
        }
      }
    }

    if (newDeleteIds.includes(id)) {
      for (let ib of inBetweenIds) {
        if (newDeleteIds.includes(ib)) {
          newDeleteIds.splice(newDeleteIds.indexOf(ib), 1);
        }
      }
    } else {
      for (let ib of inBetweenIds) {
        if (!newDeleteIds.includes(ib)) {
          newDeleteIds.push(ib);
        }
      }
    }

    setDeleteIds(newDeleteIds);
  };

  const onDeleteMultiple = () => {
    if (deleteIds.length > 0) {
      setShowDeleteModal(true);
    }
  };

  const onDeleteSingle = (event, id) => {
    event.stopPropagation();
    setDeleteId(id);
    setShowDeleteModal(true);
  };

  const onConfirmDelete = async () => {
    let newDeleteIds = deleteId !== -1 ? [deleteId] : deleteIds;
    if (newDeleteIds.length > 0) {
      const response = await aws.delete('/stats/purchases', { data: { ids: newDeleteIds } });
      if (response.data.affectedRows > 0) {
        getSales(true);
        setDeleteId(-1);
        setDeleteIds([]);
      }
    }
    setShowDeleteModal(false);
  };

  const downloadModel = () => {
    fetch('https://backend-dev.emissionsreductionnow.com/xlsx/importModelPurchases.xlsx')
      .then(response => {
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = "Modèle d'importation des achats 202401.xlsx";
          a.click();
        });
      });
  };

  const renderItemList = (itemList) => {
    return (
      <div>
        <table style={styles.table}>
          <tbody>
            {visibleItems.map((item) => {
              let itemCo2 = item.co2;
              if (item.co2 === null) {
                itemCo2 = null;
              }
              let itemColor = getColor(itemCo2, item.yield_kg, colorValues.ingredientHigh, colorValues.ingredientLow);
              if (item.co2 === null) {
                return;
              }
              return (
                <tr key={item.id} style={{ userSelect: 'none', cursor: 'pointer', backgroundColor: (hover === item.id || deleteIds.includes(item.id)) ? colors.semiLightGrey : colors.lightGrey, transition: 'background-color 0.1s ease-out' }}
                  onMouseEnter={() => handleMouseEnter(item.id)}
                  onMouseLeave={handleMouseLeave}
                  onClick={(e) => onSelect(e, item.id)} >
                  <td style={{ ...styles.firstCell, backgroundColor: itemColor }}></td>
                  <td style={styles.gap}></td>
                  <td style={styles.cell}>{i18n.language === "fr" ? item.name_fra : item.name_eng}</td>
                  <td style={{ ...styles.cell, width: 89, color: colors.text }}>{item.purchase_quantity}</td>
                  <td style={{ ...styles.cell, width: 89, color: colors.text }}>{t(completeUnits2[item.purchase_unit].code)}</td>
                  <td style={{ ...styles.cell, width: 200, color: colors.text }}>{item.date}</td>
                  <td style={styles.lastCell}>
                    <AiOutlineMinusCircle size={24} color={colors.delete} onClick={(event) => onDeleteSingle(event, item.id)} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {
          itemList.length > 0 ? null :
            <div style={{ marginTop: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div style={{ width: '70%', fontSize: 25, fontWeight: 'bold', color: colors.main, textAlign: 'center' }}>{t("textEmptyRecipes")}</div>
            </div>
        }
      </div >

    );
  };

  return (
    <div style={commonStyles.container}>
      {showDeleteModal ? <div style={styles.modalWindow}>
        <div style={{ ...styles.modalDiv, width: 200 }}>
          <div style={{ ...styles.modalText, marginBottom: 20 }}>{deleteId === -1 && deleteIds.length > 1 ? t('lblDeleteManyIngredient') : t('lblDeleteIngredient')}</div>
          <div style={styles.modalButtons}>
            <ButtonFull text={t('btnNo')} onButtonClick={() => { setShowDeleteModal(false); setDeleteId(-1) }} />
            <ButtonEmpty text={t('btnYes')} onButtonClick={() => onConfirmDelete()} />
          </div>
        </div>
      </div> : null}
      {showModalLength ? <div style={styles.modalWindow}>
        <div style={styles.modalDiv}>
          <div style={styles.modalText}>{t('lblTooMuchRows')}</div>
          <div style={styles.modalButtons}>
            <ButtonFull text={t('btnOk')} onButtonClick={() => setShowModalLength(false)} />
          </div>
        </div>
      </div> : null}
      {showModal ? <div style={styles.modalWindow}>
        <div style={{ ...styles.modalDiv, maxHeight: 600, overflowY: 'scroll' }}>
          {errorArray1.length > 0 ? <>
            <div style={styles.modalText}>{t(error1)}</div>
            <div style={styles.modalTextMonths}>
              {errorArray1.map((i, index) => <div key={index + i}>{i}</div>)}
            </div>
          </> : null}
          {errorArray2.length > 0 ? <>
            <div style={styles.modalText}>{t(error2)}</div>
            <div style={styles.modalTextMonths}>
              {errorArray2.map((i, index) => <div key={index + i}>{i}</div>)}
            </div>
          </> : null}
          {errorArray3.length > 0 ? <>
            <div style={styles.modalText}>{t(error3)}</div>
            <div style={styles.modalTextMonths}>
              {errorArray3.map((i, index) => <div key={index + i}>{i}</div>)}
            </div>
          </> : null}
          {errorArray4.length > 0 ? <>
            <div style={styles.modalText}>{t(error4)}</div>
            <div style={styles.modalTextMonths}>
              {errorArray4.map((i, index) => <div key={index + i}>{i}</div>)}
            </div>
          </> : null}
          {errorArray5.length > 0 ? <>
            <div style={styles.modalText}>{t(error5)}</div>
            <div style={styles.modalTextMonths}>
              {errorArray5.map((i, index) => <div key={index + i}>{i}</div>)}
            </div>
          </> : null}
          {errorArray6.length > 0 ? <>
            <div style={styles.modalText}>{t(error6)}</div>
            <div style={styles.modalTextMonths}>
              {errorArray6.map((i, index) => <div key={index + i}>{i}</div>)}
            </div>
          </> : null}
          <div style={styles.modalButtons}>
            <ButtonFull text={t('btnOk')} onButtonClick={() => setShowModal(false)} />
          </div>
        </div>
      </div> : null}
      {showModalImportSuccess ? <div style={styles.modalWindow}>
        <div style={styles.modalDiv}>
          <div style={styles.modalText}>{t('lblImportSuccess')}</div>
          <div style={styles.modalButtons}>
            <ButtonFull text={t('btnOk')} onButtonClick={() => setShowModalImportSuccess(false)} />
          </div>
        </div>
      </div> : null}
      {showModalImport ? <div style={styles.modalWindow}>
        <div style={styles.modalDiv}>
          <div style={styles.modalText}>{t('lblImport')}</div>
          <div style={styles.modalButtons}>
            <Box sx={{ width: '100%', mt: 1 }}>
              <LinearProgress variant="determinate" value={progress} sx={{ color: colors.main }} />
            </Box>
          </div>
        </div>
      </div> : null}
      <div style={commonStyles.title}>{t('routePurchasesData')}</div>
      <div style={commonStyles.body}>
        <div style={styles.searchContainer}>
          <div style={styles.searchBar}>
            <InputBase
              sx={{ pl: 1, flex: 1 }}
              placeholder={t('btnSearch')}
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              onKeyPress={(event) => onEnterPressed(event.key)}
            />
            <IconButton type="button" sx={{ p: 0 }} onClick={() => showItems()}>
              <SearchIcon style={{ color: colors.main }} />
            </IconButton>
          </div>
          <div style={{ marginRight: 16 }} />
          <input id="uploadSalesButton" type="file" accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={(event) => { onImport(event.target.files[0]) }} hidden />

          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <ButtonFull text={t('btnImportFile')} onButtonClick={() => document.getElementById("uploadSalesButton").click()} />
            <a style={hover === "1" ? styles.linkHover : styles.link} onMouseEnter={() => handleMouseEnter("1")} onMouseLeave={() => handleMouseLeave("")} onClick={() => downloadModel()}>{t('lblImportModel')}</a>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', position: 'sticky', top: 0, backgroundColor: colors.background, zIndex: 50 }}>
          <div style={{ display: 'flex', margin: '10px 26px 0px 26px' }}>
            <div style={{ ...styles.toolBar, overflow: 'hidden', height: deleteIds.length > 0 ? 20 : 0, transition: 'height 0.2s ease-out' }}>
              <ButtonOption width={260} text={t("lblDeleteSelected")} onButtonClick={() => onDeleteMultiple()} color={colors.bad} />
            </div>
          </div>
          <table style={styles.table}>
            <tbody>
              <tr key="header">
                <th style={{ padding: 0, width: 8 }}></th>
                <th style={{ padding: 0, width: 8 }}></th>
                <th style={{ ...styles.headerCell, cursor: 'pointer', width: 362 }} onClick={() => toggleOrder("name")}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <OrderArrows id="name" name={t('lblIngName')} stateType={orderType} stateIsAsc={isOrderAsc} />
                  </div>
                </th>
                <th style={{ ...styles.headerCell, width: 89 }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>{t('lblQuantity')}</div>
                </th>
                <th style={{ ...styles.headerCell, width: 89 }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>{t('lblUnit')}</div>
                </th>
                <th style={{ ...styles.headerCell, cursor: 'pointer', width: 200 }} onClick={() => toggleOrder("date")}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <OrderArrows id="date" name={t('lblDate')} stateType={orderType} stateIsAsc={isOrderAsc} />
                  </div>
                </th>
                <th></th>
              </tr>
            </tbody>
          </table>
        </div>
        {!isReady ?
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ReactLoading type="spin" color={colors.main} height={40} width={40} />
          </div> : renderItemList(items)
        }
      </div>
    </div>
  );
};

const styles = {
  searchContainer: {
    margin: '20px 20px 20px 20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch'
  },
  searchBar: {
    height: 32,
    display: 'flex',
    justifyContent: 'stretch',
    padding: '0 12px',
    flex: 1,
    border: '1px solid lightgrey',
    borderRadius: 12,
    backgroundColor: colors.lightGrey
  },
  toolBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },

  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0 10px'
  },
  headerCell: {
    padding: '0 20px',
    fontWeight: 400,
    textAlign: 'left',
    color: colors.text
  },
  cell: {
    padding: '10px 20px',
    fontSize: 16,
    fontWeight: '500',
  },
  firstCell: {
    width: 6,
  },
  gap: {
    width: 6,
    backgroundColor: colors.background,
  },
  lastCell: {
    width: 80,
    textAlign: 'center',
    backgroundColor: colors.background
  },
  modalWindow: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100
  },
  modalDiv: {
    width: 350,
    padding: '30px 40px',
    borderRadius: 12,
    borderStyle: 'solid',
    borderColor: colors.main,
    borderWidth: 2,
    backgroundColor: colors.background,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  modalText: {
    marginBottom: 0,
    textAlign: 'center'
  },
  modalTextMonths: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
    textAlign: 'center',
    color: colors.bad
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'space-around'
  },

  modalDivCategories: {
    position: 'absolute',
    padding: '15px 20px',
    borderRadius: 12,
    borderStyle: 'solid',
    borderColor: colors.grey,
    borderWidth: 1,
    backgroundColor: colors.background,
    display: 'flex',
    flexDirection: 'column',
  },
  modalCheckbox: {
    display: 'flex',
    alignItems: 'center'
  },
  modalCheckboxText: {
    fontSize: 14,
  },
  link: {
    marginTop: 10,
    color: colors.link,
    textDecoration: 'none',
    cursor: 'pointer'
  },
  linkHover: {
    marginTop: 10,
    color: colors.grey,
    textDecoration: 'none',
    cursor: 'pointer'
  },
};

export default PurchasesDataScreen;