import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import i18n from '../i18n';
import { AiOutlineMinusCircle } from "react-icons/ai";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
//context
import UserContext from '../context/UserContext';
//api
import aws from '../api/aws';
//components
import { Picker } from './AlternativePicker';
//util
import { colors, completeUnits, emptyUnit, massUnits, volumesUnits } from '../util/values';
import { calculateCo2FromYield, getIng, is1For1Ing, fromAnyToKg2, fromAnyToL2, fromAnyToUnit2, fromAnyToPortions2 } from '../util/climatecook';

const IngredientRow = ({ ingredient, yieldKg, totalCo2, parentId, isParent1For1, autoComplete, onDeleteCallback, onChangeCallback, onErrorCallback }) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const [unit, setUnit] = useState(emptyUnit);
  const [availiableUnits, setAvailiableUnits] = useState([emptyUnit, ...completeUnits]);
  const [quantity, setQuantity] = useState(0);
  const [quantityStr, setQuantityStr] = useState("");
  const [nameObject, setNameObject] = useState({});
  const [inputName, setInputName] = useState("");
  const [association, setAssociation] = useState("");
  const [calculatedCo2, setCalculatedCo2] = useState(0);
  const [alts, setAlts] = useState([]);
  const [parentYieldKg, setParentYieldKg] = useState(0);
  const [totalParentCo2, setTotalParentCo2] = useState(0);
  const [nameError, setNameError] = useState("");
  const [quantityError, setQuantityError] = useState("");
  const [otherAuto, setOtherAuto] = useState("");

  useEffect(() => {
    let name = i18n.language === "fr" ? ingredient.name_fra : ingredient.name_eng;
    onChangeQuantity(ingredient.quantity);
    setQuantityStr(ingredient.quantity !== null ? ingredient.quantity.toString() : "");
    setNameObject({ id: ingredient.ingredient_id, name, ernIngCode: ingredient.ern_ingredient_code });
    if (name === "") {
      setNameError(t("errorNoIngSelected"));
      onErrorCallback(true);
    }
    setInputName(name);
  }, []);

  useEffect(() => {
    let newAlts = [];
    if (ingredient.alternative) {
      newAlts.push({ id: ingredient.alternativeId, name: ingredient.alternative, co2: ingredient.altCo2, ernIngCode: ingredient.altErnIngCode });
    }
    if (ingredient.alternative2) {
      newAlts.push({ id: ingredient.alternativeId2, name: ingredient.alternative2, co2: ingredient.altCo22, ernIngCode: ingredient.altErnIngCode2 });
    }
    if (ingredient.alternative3) {
      newAlts.push({ id: ingredient.alternativeId3, name: ingredient.alternative3, co2: ingredient.altCo23, ernIngCode: ingredient.altErnIngCode3 });
    }
    if (ingredient.alternative4) {
      newAlts.push({ id: ingredient.alternativeId4, name: ingredient.alternative4, co2: ingredient.altCo24, ernIngCode: ingredient.altErnIngCode4 });
    }
    if (ingredient.alternative5) {
      newAlts.push({ id: ingredient.alternativeId5, name: ingredient.alternative5, co2: ingredient.altCo25, ernIngCode: ingredient.altErnIngCode5 });
    }
    setAlts(newAlts);
    if (ingredient.ingredient_id != null) {
      let newAvailiableUnits = [emptyUnit];
      for (let obj of completeUnits) {
        if ((ingredient.yield_kg && obj.category === "mass") || (ingredient.yield_l && obj.category === "volume") || (ingredient.yield_l && obj.category === "liquid") || (ingredient.yield_unit && obj.category === "single") || (ingredient.portions !== 0 && obj.category === "portions")) {
          newAvailiableUnits.push(obj);
        }
      }
      setAvailiableUnits(newAvailiableUnits);
      let newUnit = newAvailiableUnits.find(item => item.name === ingredient.unit);
      setUnit(newUnit ? newUnit : emptyUnit);
    } else {
      //setUnit(emptyUnit);
    }
    setAssociation(ingredient.association);
    calculateTotalCo2();
  }, [ingredient]);

  useEffect(() => {
    calculateOtherAuto(ingredient);
  }, [isParent1For1]);

  useEffect(() => {
    setQuantity(ingredient.quantity);
    calculateTotalCo2();
  }, [ingredient.quantity]);

  useEffect(() => {
    setTotalParentCo2(totalCo2);
  }, [totalCo2]);

  useEffect(() => {
    setParentYieldKg(yieldKg);
  }, [yieldKg]);

  useEffect(() => {
    calculateTotalCo2();
  }, [unit, quantity, parentYieldKg]);

  const calculateTotalCo2 = () => {
    //const newCo2 = parentYieldKg > 0 ? calculateCo2FromYield([{ ...ingredient, unit: unit.name, quantity }]) / parentYieldKg : calculateCo2FromYield([{ ...ingredient, unit: unit.name, quantity }]);
    const newCo2 = calculateCo2FromYield([{ ...ingredient, unit: unit.name, quantity }]);
    setCalculatedCo2(newCo2);
  };

  const calculateOtherAuto = (ing) => {
    if (isParent1For1) {
      let newOtherAuto = 'eq. ';
      newOtherAuto += getYield(unit.name, ing);
      setOtherAuto(newOtherAuto);
    } else {
      setOtherAuto("");
    }
  };

  const getYield = (unitName, ing) => {
    if (massUnits[unitName] == null && ing.yield_kg !== null) {
      return fromAnyToKg2(unit.name, quantity, ing.yield_kg, ing.yield_l, ing.yield_unit, ing.portions).toFixed(2) + ' ' + t('unitKg');
    } else if (volumesUnits[unitName] == null && ing.yield_l !== null) {
      return fromAnyToL2(unit.name, quantity, ing.yield_kg, ing.yield_l, ing.yield_unit, ing.portions).toFixed(2) + ' ' + t('unitL');
    } else if (unitName !== 'unit' && ing.yield_unit !== null) {
      return fromAnyToUnit2(unit.name, quantity, ing.yield_kg, ing.yield_l, ing.yield_unit, ing.portions).toFixed(2) + ' ' + t('unitUnit');
    } else if (unitName !== 'portions' && ing.portions !== 0) {
      return fromAnyToPortions2(unit.name, quantity, ing.yield_kg, ing.yield_l, ing.yield_unit, ing.portions).toFixed(2) + ' ' + t('unitPortions');
    }
  };

  const onChangeQuantity = (value) => {
    setQuantityStr(value);
    let newQuantity = 0;
    let newQuantityError = t("errorNoQuantity");
    if (value && !isNaN(value) && Number(value) !== 0) {
      newQuantity = Number(value);
      newQuantityError = "";
    }
    setQuantityError(newQuantityError);

    if (newQuantity === 0) {
      onErrorCallback(true);
    } else if (!checkForError(newQuantityError, unit.name, nameError)) {
      onErrorCallback(false);
    }

    onChangeCallback("quantity", newQuantity, ingredient.uniqueId);
  };

  const onChangeUnit = (value) => {
    setUnit(value);
    if (value.name === "...") {
      onErrorCallback(true);
    } else if (!checkForError(quantityError, value.name, nameError)) {
      onErrorCallback(false);
    }
    onChangeCallback("unit", value, ingredient.uniqueId);
  };

  const checkForError = (quantityError1, unit1, nameError1) => {
    if (quantityError1 !== "" || unit1 === "..." || nameError1 !== "" || ingredient.yield_kg === null) {
      return true;
    } else {
      return false;
    }
  };

  const onChangeInputName = (newInputValue) => {
    setInputName(newInputValue);
    setNameObject({ id: null, name: newInputValue, ernIngCode: null });
    setNameError(t("errorNoIngSelected"));
    onErrorCallback(true);
    let newIngredient = { ...ingredient, co2: 0 };
    onChangeCallback("name", newIngredient, newIngredient.uniqueId);
  };

  const onChangeName = async (newValue) => {
    if (newValue === null) {
      return;
    }
    setNameObject(newValue);
    const response = await aws.get(`/ingredients/composition/${newValue.id}`, { params: { userId: user[0].user_id, lang: i18n.language, region: 'CA' } });
    if (response.data) {
      let ing = response.data;
      let newNameError = "";
      for (let i = 0; i < ing.length; i++) {
        if (ing[i].ingredient_id === parentId) {
          newNameError = t("errorCircularRef");
        }
      }
      setNameError(newNameError);
      if (newNameError !== "") {
        onErrorCallback(true);
        return;
      }

      /*let newCo2 = 0;
      if (ing[0].co2 === null) {
        newCo2 = getIngredientCo2(ing, ing[0]);
      } else {
        newCo2 = ing[0].co2;
      }*/
      let newIngredient = getIng(ing, ing[0]);

      let newAvailiableUnits = [emptyUnit];
      for (let obj of completeUnits) {
        if ((newIngredient.yield_kg && obj.category === "mass") || (newIngredient.yield_l && obj.category === "volume") || (newIngredient.yield_l && obj.category === "liquid") || (newIngredient.yield_unit && obj.category === "single")) {
          newAvailiableUnits.push(obj.name);
        }
      }

      let newUnit = newAvailiableUnits.includes(unit.name) ? unit.name : newIngredient.main_unit
      if (!checkForError(quantityError, newUnit, newNameError)) {
        onErrorCallback(false);
      }

      newIngredient.quantity = quantity;
      newIngredient.unit = newUnit;
      newIngredient.uniqueId = ingredient.uniqueId;
      onChangeCallback("name", newIngredient, newIngredient.uniqueId);
    }
  };

  const onDelete = () => {
    onDeleteCallback(ingredient.uniqueId);
  };

  const renderSelect = () => {
    return (
      <Select
        sx={{ ...styles.unit, '&:before': { border: 'none' }, '&:after': { border: 'none' }, '&:not(.Mui-disabled):hover::before': { border: 'none' }, '.MuiSelect-icon': { display: 'none' }, '.MuiSelect-select': { padding: '0 !important', width: 50 } }}
        variant="standard" value={unit} onChange={(event) => onChangeUnit(event.target.value)}
      >
        {availiableUnits.map(item => <MenuItem key={ingredient.uniqueId + item.code} value={item}>{t(item.code)}</MenuItem>)}
      </Select>
    );
  };

  return (
    <>
      <tr key={ingredient.uniqueId + "values"}>
        <td style={{ ...styles.cell, width: 100 }}>
          <TextField sx={{ input: { fontFamily: 'Inter' }, '& .MuiInput-underline:after': { borderBottomColor: 'black' } }}
            id="quantity"
            variant="standard"
            value={quantityStr}
            error={quantityError !== ""}
            helperText={quantityError}
            onChange={(event) => onChangeQuantity(event.target.value)}
            fullWidth
          />
        </td>
        <td style={{ ...styles.cell, width: 0 }}>
          {renderSelect()}
        </td>
        <td style={{ ...styles.cell }}>
          <Autocomplete
            freeSolo
            sx={{ input: { fontFamily: 'Inter' }, '& .MuiInput-underline:after': { borderBottomColor: 'black' } }}
            value={nameObject}
            onChange={(event, newValue) => onChangeName(newValue)}
            inputValue={inputName}
            onInputChange={(event, newInputValue) => onChangeInputName(newInputValue)}
            options={autoComplete}
            //isOptionEqualToValue={(option, value) => option.name === value.name}
            /*filterOptions={(options) => {
              if (nameObject && nameObject.name.length > 0) {
                return options.filter(item => item.name.toLowerCase().indexOf(nameObject.name.toLowerCase()) !== -1);
              } else {
                return options;
              }
            }}*/
            getOptionLabel={option => option.name ? option.name : ""}
            renderInput={(params) => <TextField {...params} error={nameError !== ""} helperText={nameError} style={{ minWidth: 200 }} id="name" variant="standard" fullWidth />}
          />
        </td>
        <td style={{ ...styles.cell, color: colors.text }}>{nameObject.id !== null && association !== inputName ? association : ""}</td>
        <td style={styles.lastCell}>
          <AiOutlineMinusCircle style={{ cursor: 'pointer' }} size={24} color={colors.delete} onClick={() => onDelete()} />
        </td>
      </tr>
      <tr key={ingredient.uniqueId + "space"} style={{ height: 12 }}>
        <td colSpan={4} style={{ padding: '0 20px 4px 20px', color: colors.grey, fontSize: 14 }}>
          <div>{otherAuto}</div>
        </td>
      </tr>
      {alts.length > 0 ?
        <tr>
          <td><img style={{ paddingLeft: 20, width: 25 }} src={require('../assets/altarrow.png')} /></td>
          <td></td>

          <td style={{ display: 'flex', alignItems: 'center' }}>
            <Picker list={alts} ingredientCo2={ingredient.co2} onItemPicked={(item) => onChangeName(item)} />
          </td>
        </tr>

        /*<tr key={ingredient.uniqueId + "alternative"} style={{}}>
          <td><img style={{ paddingLeft: 20, width: 25 }} src={require('../assets/altarrow.png')} /></td>
          <td></td>
          <td style={{ ...styles.cell, color: colors.main, fontWeight: 700, display: 'flex', cursor: 'pointer' }} colSpan={4} onClick={() => onChangeName({ id: ingredient.alternativeId, name: ingredient.alternative, ernIngCode: ingredient.altErnIngCode })}>
            <div style={{ marginRight: 6 }}>{t("lblAlternative")} </div>
            <div style={{ marginRight: 50 }}>{alternative}</div>
            <div style={{ marginRight: 6 }}>{((ingredient.co2 - altCo2) * 100 / ingredient.co2).toFixed(0)}% </div>
            <div>{t("lblBetter")}</div>
          </td>
        </tr>*/ : null
      }
      <tr key={ingredient.uniqueId + "co2"} style={{ backgroundColor: colors.lightGrey }}>
        <td colSpan={4} style={{ padding: 0 }}>
          <div style={{ width: (totalParentCo2 > 0 ? calculatedCo2 * 100 / totalParentCo2 : 0) + "%", height: 12, backgroundColor: colors.darkGray }}></div>
        </td>
      </tr>
    </>
  );
};

const styles = {
  cell: {
    padding: '10px 20px',
    fontSize: 16,
    fontWeight: '500',
  },
  lastCell: {
    width: 80,
    textAlign: 'center',
    backgroundColor: colors.background
  },
  unit: {
    fontFamily: 'Inter',
    width: 50,
    fontSize: 15,
    fontWeight: 600,
    color: colors.darkGray,
    textAlign: 'center',
    backgroundColor: colors.grey,
    borderRadius: 1.5,
    borderStyle: 'solid',
    borderColor: colors.darkGray,
    borderWidth: 1
  },
};

export default IngredientRow;