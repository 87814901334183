import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from "react-i18next";
import i18n from '../i18n';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ReactLoading from 'react-loading';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
//context
import UserContext from '../context/UserContext';
//api
import aws from '../api/aws';
//components
import { KgCO2e } from '../components/KgCO2e';
import IngredientRow from '../components/IngredientRow';
import { ButtonFull, ButtonEmptyNoSpace } from '../components/Buttons';
import Yield from '../components/Yield';
//util
import { colors, completeUnits, emptyUnit, massUnits, volumesUnits, colorValues } from '../util/values';
import { commonStyles } from '../util/styles';
import { sortBy, calculateCo2, calculateCo2FromYield, getColor, getCount, is1For1Ing, fromAnyToKg2, fromAnyToL2, fromAnyToUnit2, fromAnyToPortions2 } from '../util/climatecook';

const IngredientScreen = () => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [title, setTitle] = useState("");
  const [ingredients, setIngredients] = useState([]);
  const [newIngredients, setNewIngredients] = useState([]);
  const [id, setId] = useState(null);
  const [name1, setName1] = useState("");
  const [name2, setName2] = useState("");
  const [name1Error, setName1Error] = useState("");
  const [name2Error, setName2Error] = useState("");
  const [totalCo2, setTotalCo2] = useState(0);
  const [rowErrors, setRowErrors] = useState({});

  const [availableUnits, setAvailableUnits] = useState([emptyUnit, completeUnits[6], completeUnits[10], completeUnits[5], completeUnits[14]]);
  const [yieldKg, setYieldKg] = useState(0);
  const [yieldL, setYieldL] = useState(0);
  const [yieldUnit, setYieldUnit] = useState(0);
  const [mainYield, setMainYield] = useState("kg");
  const [unitMainYield, setUnitMainYield] = useState(availableUnits[0]);
  const [unitYield2, setUnitYield2] = useState(availableUnits[0]);
  const [unitYield3, setUnitYield3] = useState(availableUnits[0]);
  const [quantityMainYield, setQuantityMainYield] = useState(0);
  const [quantityYield2, setQuantityYield2] = useState(0);
  const [quantityYield3, setQuantityYield3] = useState(0);
  const [yieldIsAutoMain, setYieldIsAutoMain] = useState(false);
  const [yieldIsAuto2, setYieldIsAuto2] = useState(false);
  const [yieldIsAuto3, setYieldIsAuto3] = useState(false);
  const [isParent1For1, setIsParent1For1] = useState(false);
  const [portions, setPortions] = useState(0);

  const [categories, setCategories] = useState([{ id: null, name_eng: 'None', name_fr: 'Aucune', thresholdHigh: colorValues.ingredientHigh, thresholdLow: colorValues.ingredientLow }]);
  const [category, setCategory] = useState(categories[0]);
  const [isNew, setIsNew] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const [autoCompleteItems, setAutoCompleteItems] = useState([]);
  const [globalColor, setGlobalColor] = useState(colors.good);
  const [allIngs, setAllIngs] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [updateScroll, setUpdateScroll] = useState(0);
  const scrollBotRef = useRef(null);

  useEffect(() => {
    let nav = JSON.parse(localStorage.getItem("nav"));
    let newTitle = 'routeMyIngredients';
    if ((params.which === 'user' && location.state.which === 'subing') || params.which === 'subing') {
      newTitle = nav != null ? nav[i18n.language].subRecipes : 'routeMySubRecipes';
    } else if ((params.which === 'user' && location.state.which === 'ing') || params.which === 'ing') {
      newTitle = nav != null ? nav[i18n.language].ingredients : 'routeMyIngredients';
    }
    setTitle(newTitle);
    getCategories();
    getAllIngredients();
  }, []);

  useEffect(() => {
    getIngredients();
  }, [categories]);

  useEffect(() => {
    calculateTotalCo2(newIngredients);
    let mainIng = {
      name_fra: i18n.language === 'fr' ? name1 : name2,
      name_eng: i18n.language === 'fr' ? name2 : name1,
      owner: user[0].user_id,
      yield_kg: yieldKg,
      yield_l: yieldL,
      yield_unit: yieldUnit,
      portions: portions,
      composition: newIngredients
    };
    let [is1For1, other1For1] = is1For1Ing(mainIng);
    setIsParent1For1(is1For1);
    if (yieldIsAutoMain) {
      let changed = changeYieldBasedOnUnit(mainIng, unitMainYield, setQuantityMainYield);
      if (!changed) {
        setQuantityMainYield(0);
        setYieldIsAutoMain(false);

      }
    }
    if (yieldIsAuto2) {
      let changed = changeYieldBasedOnUnit(mainIng, unitYield2, setQuantityYield2);
      if (!changed) {
        setQuantityYield2(0);
        setYieldIsAuto2(false);
      }
    }
    if (yieldIsAuto3) {
      let changed = changeYieldBasedOnUnit(mainIng, unitYield3, setQuantityYield3);
      if (!changed) {
        setQuantityYield3(0);
        setYieldIsAuto3(false);
      }
    }
  }, [yieldKg, newIngredients]);

  useEffect(() => {
    scrollBotRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [updateScroll]);

  const getCategories = async () => {
    const response = await aws.get('/categories', { params: { owner: user[0].user_id, isAll: true, isErn: true, isRecipeCat: 0 } });
    if (response.data) {
      let newCategories = [categories[0]];
      for (let cat of response.data) {
        newCategories.push({ id: cat.id, name_eng: cat.name_eng, name_fr: cat.name_fr, thresholdHigh: colorValues.ingredientHigh, thresholdLow: colorValues.ingredientLow });
      }
      setCategories(newCategories);
    }
  };

  const getAllIngredients = async () => {
    setIsReady(false);
    const response = await aws.get('/ingredients', { params: { type: 'allIngs', owner: user[0].user_id, lang: i18n.language } });
    if (response.data) {
      let newAllIngs = [];
      let autoCompleteIngs = [];
      for (let i = 0; i < response.data.length; i++) {
        if (i18n.language === 'fr') {
          if (response.data[i].name_fra !== null && response.data[i].name_fra !== "") {
            autoCompleteIngs.push({ id: response.data[i].id, name: response.data[i].name_fra, ernIngCode: response.data[i].ernIngCode, owner: response.data[i].owner });
            newAllIngs.push(response.data[i].name_fra.toLowerCase());
          }
        } else {
          if (response.data[i].name_eng !== null && response.data[i].name_eng !== "") {
            autoCompleteIngs.push({ id: response.data[i].id, name: response.data[i].name_eng, ernIngCode: response.data[i].ernIngCode, owner: response.data[i].owner });
            newAllIngs.push(response.data[i].name_eng.toLowerCase());
          }
        }
      }

      for (let a of autoCompleteIngs) {
        let dupIndex = autoCompleteIngs.findIndex(o => o.id !== a.id && o.name === a.name);
        if (dupIndex !== -1) {
          if (a.owner === null) {
            a.name += " (ccresto)";
          } else if (autoCompleteIngs[dupIndex].owner != null) {
            autoCompleteIngs[dupIndex].name += " (" + autoCompleteIngs[dupIndex].owner + ")";
          }
        }
      }

      autoCompleteIngs = autoCompleteIngs.sort(sortBy('name', true));
      setAutoCompleteItems(autoCompleteIngs);
      setAllIngs(newAllIngs);
    }
  };

  const getIngredients = () => {
    setIsReady(false);
    setIsNew(location.state.isNew ? location.state.isNew : false);

    const ingredient = location.state.ingredient;
    let mainIng = ingredient;
    let newIngs = ingredient.composition ? ingredient.composition : [];
    setId(mainIng.ingredient_id);
    setName1(i18n.language === 'fr' ? mainIng.name_fra : mainIng.name_eng);
    setName2(i18n.language === 'fr' ? mainIng.name_eng : mainIng.name_fra);
    let newCategory = categories[0];
    for (let j = 0; j < categories.length; j++) {
      if (categories[j].id == mainIng.food_group) {
        newCategory = categories[j];
        break;
      }
    }
    setCategory(newCategory);

    let [is1For1, other1For1] = is1For1Ing(mainIng);
    let yieldKgValue = other1For1.kg.is1For1 && mainIng.isAutoKg ? other1For1.kg.value : mainIng.yield_kg;
    let yieldLValue = other1For1.l.is1For1 && mainIng.isAutoL ? other1For1.l.value : mainIng.yield_l;
    let yieldUnitValue = other1For1.unit.is1For1 && mainIng.isAutoUnit ? other1For1.unit.value : mainIng.yield_unit;
    let yieldPortionsValue = other1For1.portions.is1For1 && mainIng.isAutoPortions ? other1For1.portions.value : mainIng.portions;
    setYieldKg(mainIng.yield_kg === null ? 0 : yieldKgValue);
    setYieldL(mainIng.yield_l === null ? 0 : yieldLValue);
    setYieldUnit(mainIng.yield_unit === null ? 0 : yieldUnitValue);
    setPortions(mainIng.portions === null ? 0 : yieldPortionsValue);

    //add yields depending on which one exists
    const obj = [{ type: massUnits, unit: availableUnits[1], quantity: yieldKgValue, auto: other1For1.kg.is1For1 && mainIng.isAutoKg },
    { type: volumesUnits, unit: availableUnits[2], quantity: yieldLValue, auto: other1For1.l.is1For1 && mainIng.isAutoL },
    { type: { unit: 1 }, unit: availableUnits[3], quantity: yieldUnitValue, auto: other1For1.unit.is1For1 && mainIng.isAutoUnit },
    { type: { portions: 1 }, unit: availableUnits[4], quantity: yieldPortionsValue, auto: other1For1.portions.is1For1 && mainIng.isAutoPortions }
    ];
    let isFirstSet = false, isSecondSet = false;
    /*obj.forEach(o => {
      if (o.type[mainIng.main_unit]) {
        setUnitMainYield(o.unit);
        setQuantityMainYield(o.quantity);
        setYieldIsAutoMain(o.auto);
      } else if (o.quantity > 0 && !isSecondSet) {
        isSecondSet = true;
        setUnitYield2(o.unit);
        setQuantityYield2(o.quantity);
        setYieldIsAuto2(o.auto);
      } else if (o.quantity > 0) {
        setUnitYield3(o.unit);
        setQuantityYield3(o.quantity);
        setYieldIsAuto3(o.auto);
      }
    });*/
    obj.forEach(o => {
      console.log(o.unit.name, o.quantity, isFirstSet, isSecondSet);
      if (o.quantity == null || o.quantity === 0);
      else if (!isFirstSet) {
        isFirstSet = true;
        setUnitMainYield(o.unit);
        setQuantityMainYield(o.quantity);
        setYieldIsAutoMain(o.auto);
      } else if (!isSecondSet) {
        isSecondSet = true;
        setUnitYield2(o.unit);
        setQuantityYield2(o.quantity);
        setYieldIsAuto2(o.auto);
      } else {
        setUnitYield3(o.unit);
        setQuantityYield3(o.quantity);
        setYieldIsAuto3(o.auto);
      }
    });

    setIngredients(newIngs);
    setNewIngredients(newIngs);
    setIsReady(true);
  };

  const calculateTotalCo2 = (itemList) => {
    let newTotalCo2 = calculateCo2FromYield(itemList);
    setTotalCo2(newTotalCo2);
    setGlobalColor(getColor(newTotalCo2, yieldKg, category.thresholdHigh, category.thresholdLow));
  };

  useEffect(() => {
    let newYieldKg = 0, newYieldL = 0, newYieldUnit = 0, newYieldPortions = 0;
    let unitsArray = [unitMainYield, unitYield2, unitYield3];
    let quantitiesArray = [quantityMainYield, quantityYield2, quantityYield3];
    for (let i = 0; i < unitsArray.length; i++) {
      if (unitsArray[i].name === "kg") {
        newYieldKg = quantitiesArray[i];
      } else if (unitsArray[i].name === "l") {
        newYieldL = quantitiesArray[i];
      } else if (unitsArray[i].name === "unit") {
        newYieldUnit = quantitiesArray[i];
      } else if (unitsArray[i].name === "portions") {
        newYieldPortions = quantitiesArray[i];
      }
    }
    setYieldKg(newYieldKg);
    setYieldL(newYieldL);
    setYieldUnit(newYieldUnit);
    setPortions(newYieldPortions);
  }, [unitMainYield, unitYield2, unitYield3, quantityMainYield, quantityYield2, quantityYield3]);

  const onChangeUnit = (key, value) => {
    if (key === 0) {
      setMainYield(value.name);
    }

    let unitsArray = [unitMainYield, unitYield2, unitYield3];
    let setUnitsArray = [setUnitMainYield, setUnitYield2, setUnitYield3];
    for (let i = 0; i < unitsArray.length; i++) {
      if (i === key) {
        setUnitsArray[i](value);
      } else if (unitsArray[i] === value) {
        setUnitsArray[i](availableUnits[0]);
      }
    }
  };

  const onChangeQuantity = (key, value) => {
    if (key === 0) {
      setQuantityMainYield(value);
    } else if (key === 1) {
      setQuantityYield2(value);
    } else if (key === 2) {
      setQuantityYield3(value);
    }
  };

  const onChangeIsAuto = (key, value) => {
    if (value) {
      let mainIng = {
        name_fra: i18n.language === 'fr' ? name1 : name2,
        name_eng: i18n.language === 'fr' ? name2 : name1,
        owner: user[0].user_id,
        yield_kg: yieldKg,
        yield_l: yieldL,
        yield_unit: yieldUnit,
        portions: portions,
        composition: newIngredients
      };

      if (key === 0 && unitMainYield !== emptyUnit) {
        let changed = changeYieldBasedOnUnit(mainIng, unitMainYield, setQuantityMainYield);
        if (changed) {
          setYieldIsAutoMain(value);
        }
      }
      if (key === 1 && unitYield2 !== emptyUnit) {
        let changed = changeYieldBasedOnUnit(mainIng, unitYield2, setQuantityYield2);
        if (changed) {
          setYieldIsAuto2(value);
        }
      }
      if (key === 2 && unitYield3 !== emptyUnit) {
        let changed = changeYieldBasedOnUnit(mainIng, unitYield3, setQuantityYield3);
        if (changed) {
          setYieldIsAuto3(value);
        }
      }

      //let [is1For1, other1For1] = is1For1Ing(mainIng);
      //if (!is1For1) {
      //  return;
      //}
      //if (key === 0) {
      //  changeYieldBasedOnUnit(other1For1, unitMainYield, setQuantityMainYield);
      //} else if (key === 1) {
      //  changeYieldBasedOnUnit(other1For1, unitYield2, setQuantityYield2);
      //} else if (key === 2) {
      //  changeYieldBasedOnUnit(other1For1, unitYield3, setQuantityYield3);
      //}
    } else {
      if (key === 0) {
        setYieldIsAutoMain(value);
      } else if (key === 1) {
        setYieldIsAuto2(value);
      } else if (key === 2) {
        setYieldIsAuto3(value);
      }
    }
  };

  const changeYieldBasedOnUnit = (ing, unitYield, setQuantityYieldFunc) => {
    if (ing.composition.length !== 1 || ing.composition[0].quantity === 0 || (ing.composition[0].name_fra === '' && ing.composition[0].name_eng === '')) {
      return false;
    }
    if (unitYield.name === "kg") {
      if (ing.composition[0].yield_kg === null) {
        return false;
      }
      setQuantityYieldFunc(+fromAnyToKg2(ing.composition[0].unit, ing.composition[0].quantity, ing.composition[0].yield_kg, ing.composition[0].yield_l, ing.composition[0].yield_unit, ing.composition[0].portions).toFixed(4));
    } else if (unitYield.name === "l") {
      if (ing.composition[0].yield_l === null) {
        return false;
      }
      setQuantityYieldFunc(+fromAnyToL2(ing.composition[0].unit, ing.composition[0].quantity, ing.composition[0].yield_kg, ing.composition[0].yield_l, ing.composition[0].yield_unit, ing.composition[0].portions).toFixed(4));
    } else if (unitYield.name === "unit") {
      if (ing.composition[0].yield_unit === null) {
        return false;
      }
      setQuantityYieldFunc(+fromAnyToUnit2(ing.composition[0].unit, ing.composition[0].quantity, ing.composition[0].yield_kg, ing.composition[0].yield_l, ing.composition[0].yield_unit, ing.composition[0].portions).toFixed(4));
    } else if (unitYield.name === "portions") {
      if (ing.composition[0].portions === null || ing.composition[0].portions === 0) {
        return false;
      }
      setQuantityYieldFunc(+fromAnyToPortions2(ing.composition[0].unit, ing.composition[0].quantity, ing.composition[0].yield_kg, ing.composition[0].yield_l, ing.composition[0].yield_unit, ing.composition[0].portions).toFixed(4));
    }
    return true;
  };

  const onAddIngredient = () => {
    let newIngredient = {
      ing_level: 1,
      main_ingredient_id: null,
      ingredient_id: null,
      unit: "...",
      quantity: 0,
      name_fra: "",
      name_eng: "",
      main_unit: "gr",
      owner: user[0].user_id,
      density: 1,
      unit_single: 1,
      unit_mass: 1,
      unit_volume: 1,
      unit_liquid: 1,
      unitWeight: 1,
      yield_kg: 1,
      yield_l: null,
      yield_unit: null,
      food_group: null,
      ern_ingredient_code: null,
      partner_ingredient_code: null,
      co2: 0,
      region: null,
      alternative: null,
      altErnIngCode: null,
      altPatnerIngCode: null,
      altCo2: null,
      altRegion: null,
      uniqueId: getCount()
    };
    setIngredients([...ingredients, newIngredient]);
    setNewIngredients([...newIngredients, newIngredient]);
    setUpdateScroll(updateScroll + 1);
  };

  const onDeleteIngredient = (uniqueId) => {
    setIngredients(ingredients.filter(item => item.uniqueId !== uniqueId));
    let newNewIngredients = newIngredients.filter(item => item.uniqueId !== uniqueId);
    setNewIngredients(newNewIngredients);
    setRowErrors({ ...rowErrors, [uniqueId]: false });
  };

  const onChangeIngredient = (type, obj, uniqueId) => {
    let newNewIngredients = [...newIngredients];
    for (let i = 0; i < newNewIngredients.length; i++) {
      if (newNewIngredients[i].uniqueId === uniqueId) {
        if (type === "quantity") {
          newNewIngredients[i].quantity = obj;
        } else if (type === "unit") {
          newNewIngredients[i].unit = obj.name;
          setIngredients(newNewIngredients);
        } else if (type === "name") {
          newNewIngredients[i] = obj;
          setIngredients(newNewIngredients);
        }
        break;
      }
    }
    setNewIngredients(newNewIngredients);
  };

  const onChangeName = (which, value) => {
    if (which === 1) {
      setName1(value);
      if (value === "") {
        setName1Error(t("errorNameIsEmpty"));
      } else if ((i18n.language === 'fr' && value.toLowerCase() !== location.state.ingredient.name_fra.toLowerCase() && allIngs.includes(value.toLowerCase())) ||
        (i18n.language === 'en' && value.toLowerCase() !== location.state.ingredient.name_eng.toLowerCase() && allIngs.includes(value.toLowerCase()))) {
        setName1Error(t('errorNameAlreadyExists'));
      } else {
        setName1Error("");
      }
    } else {
      setName2(value);
      if ((i18n.language === 'fr' && value.toLowerCase() !== location.state.ingredient.name_fra.toLowerCase() && allIngs.includes(value.toLowerCase())) ||
        (i18n.language === 'en' && value.toLowerCase() !== location.state.ingredient.name_eng.toLowerCase() && allIngs.includes(value.toLowerCase()))) {
        setName2Error(t('errorNameAlreadyExists'));
      } else {
        setName2Error("");
      }
    }
  };

  const checkIfError = () => {
    if (totalCo2 == 0 || /*yieldKg === null || yieldKg === 0 || */name1Error !== "" || name2Error !== "" || name1 === "") {
      return true;
    }
    for (let key in rowErrors) {
      if (rowErrors[key] === true) {
        return true
      }
    }
    return false;
  };

  const onSaveIngredient = async () => {
    if (checkIfError()) {
      setShowModal(true);
      return;
    }
    setIsReady(false);
    let newYieldKg = yieldKg !== 0 ? yieldKg : null;
    let newYieldL = yieldL !== 0 ? yieldL : null;
    let newYieldUnit = yieldUnit !== 0 ? yieldUnit : null;
    let newYieldPortions = portions !== null && portions !== 0 ? portions : null;

    let unitsArray = [unitMainYield, unitYield2, unitYield3];
    let autoArray = [yieldIsAutoMain, yieldIsAuto2, yieldIsAuto3];
    for (let i = 0; i < unitsArray.length; i++) {
      if (unitsArray[i].name === "kg") {
        newYieldKg = autoArray[i] ? -1 : newYieldKg;
      } else if (unitsArray[i].name === "l") {
        newYieldL = autoArray[i] ? -1 : newYieldL;
      } else if (unitsArray[i].name === "unit") {
        newYieldUnit = autoArray[i] ? -1 : newYieldUnit;
      } else if (unitsArray[i].name === "portions") {
        newYieldPortions = autoArray[i] ? -1 : newYieldPortions;
      }
    }

    let nameFr = name2;
    let nameEn = name1;
    if (i18n.language === "fr") {
      nameFr = name1;
      nameEn = name2
    }

    let mainIng = {
      name_fra: i18n.language === 'fr' ? name1 : name2,
      name_eng: i18n.language === 'fr' ? name2 : name1,
      owner: user[0].user_id,
      yield_kg: yieldKg,
      yield_l: yieldL,
      yield_unit: yieldUnit,
      portions: portions,
      composition: newIngredients
    };
    let [is1For1, other1For1] = is1For1Ing(mainIng);
    let isRecipe = 0;
    if ((other1For1.kg.is1For1 || other1For1.kg.value === null) && (other1For1.l.is1For1 || other1For1.l.value === null) && (other1For1.unit.is1For1 || other1For1.unit.value === null) && (other1For1.portions.is1For1 || other1For1.portions.value === null)) {
      isRecipe = 2;
    }

    let mainIngredient = { ...location.state.ingredient, composition: [], name_fra: nameFr, name_eng: nameEn, main_unit: mainYield, yield_kg: newYieldKg, yield_l: newYieldL, yield_unit: newYieldUnit, portions: newYieldPortions, food_group: category.id, is_recipe: isRecipe };
    await aws.post('/ingredients/composition', { owner: user[0].user_id, mainIngredient, isNew, ingredients: newIngredients });

    localStorage.removeItem("ingredientValues");
    setIsReady(true);
    navigate("/ingredients/" + location.state.previousPage);
  };

  const renderItemList = (itemList) => {
    return (
      <table style={styles.table}>
        <tbody>
          {itemList.map((item) => {
            return <IngredientRow
              key={item.uniqueId}
              ingredient={item}
              yieldKg={yieldKg}
              totalCo2={totalCo2}
              parentId={id}
              isParent1For1={isParent1For1}
              autoComplete={autoCompleteItems}
              onDeleteCallback={onDeleteIngredient}
              onChangeCallback={onChangeIngredient}
              onErrorCallback={(value) => setRowErrors({ ...rowErrors, [item.uniqueId]: value })}
            />
          })}
        </tbody>
      </table>
    );
  };

  return (
    <div style={commonStyles.container}>
      {showModal ? <div style={styles.modalWindow}>
        <div style={styles.modalDiv}>
          <div style={styles.modalText}>{/*yieldKg === null || yieldKg === 0 ? t('errorYieldKg') : */t('errorAlert')}</div>
          <div style={styles.modalButtons}>
            <ButtonFull text={t('btnOk')} onButtonClick={() => setShowModal(false)} />
          </div>
        </div>
      </div> : null}
      <div style={commonStyles.title}>{t(title)}</div>
      <div style={commonStyles.body}>
        <div style={styles.topContainer}>
          <div style={styles.topButtonContainer}>
            <ButtonEmptyNoSpace text={t('btnCancel')} onButtonClick={() => navigate("/ingredients/" + location.state.previousPage)} />
            <ButtonFull text={t('btnSaveIngredient')} color={globalColor} onButtonClick={() => onSaveIngredient()} disabled={checkIfError()} isFakeDisabled={true} />
          </div>
          <div style={styles.topTitleContainer}>
            <div style={styles.titleRow}>
              <TextField sx={{ input: { ...styles.name1, color: globalColor, fontFamily: 'Inter', padding: 0 } }}
                InputProps={{ disableUnderline: true }}
                error={name1Error !== ""}
                helperText={name1Error}
                id="name1"
                variant="standard"
                placeholder={t('lblName', { lng: i18n.language === "fr" ? "fr" : "en" })}
                value={name1}
                onChange={(event) => onChangeName(1, event.target.value)}
              />
              <div style={{ ...styles.name1, color: globalColor }}>{t('lblIngredientImpact')}{!yieldKg || yieldKg == 0 ? '' : (totalCo2 / yieldKg).toFixed(3)}</div>
            </div>
            <div style={styles.titleRow}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ ...styles.name2, color: colors.text }}>{i18n.language === "fr" ? "EN:" : "FR:"}</div>
                <TextField sx={{ input: { ...styles.name2, color: globalColor, fontFamily: 'Inter', padding: 0 } }}
                  InputProps={{ disableUnderline: true }}
                  error={name2Error !== ""}
                  helperText={name2Error}
                  id="name2"
                  variant="standard"
                  placeholder={t('lblName', { lng: i18n.language === "fr" ? "en" : "fr" })}
                  value={name2}
                  onChange={(event) => onChangeName(2, event.target.value)}
                />
              </div>
              <div><KgCO2e size={12} /></div>
            </div>
          </div>
          <div style={styles.topYieldContainer}>
            <div style={styles.yieldRow}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ color: colors.text, fontWeight: 400, marginRight: 40 }}>{t('lblCategory')}</div>
                <Select sx={{ ...styles.name2, color: globalColor, fontFamily: 'Inter', fontWeight: 400, pb: 0, ':after': { borderBottomColor: 'black' } }} variant="standard" value={category} onChange={(event) => setCategory(event.target.value)}>
                  {categories.map(item => <MenuItem key={item.id + item.name_eng} value={item}>{i18n.language === 'fr' ? item.name_fr : item.name_eng}</MenuItem>)}
                </Select>
              </div>
              <Yield yieldKey={0}
                label={t('lblYield')}
                availableUnits={availableUnits}
                unit={unitMainYield}
                onChangeUnit={onChangeUnit}
                quantity={quantityMainYield}
                error={quantityMainYield === 0 ? t('errorNoMainYield') : ""}
                onChangeQuantity={onChangeQuantity}
                onChangeIsAuto={onChangeIsAuto}
                isAuto={yieldIsAutoMain} />
            </div>
            <div style={styles.yieldRow}>
              <div></div>
              <Yield yieldKey={1}
                label={t('lblEquiv')}
                availableUnits={availableUnits}
                unit={unitYield2}
                onChangeUnit={onChangeUnit}
                quantity={quantityYield2}
                error=""
                onChangeQuantity={onChangeQuantity}
                onChangeIsAuto={onChangeIsAuto}
                isAuto={yieldIsAuto2} />
            </div>
            {quantityYield2 !== null && quantityYield2 !== 0 && unitYield2.name !== '...' ?
              <div style={styles.yieldRow}>
                <div></div>
                <Yield yieldKey={2}
                  label={t('lblEquiv')}
                  availableUnits={availableUnits}
                  unit={unitYield3}
                  onChangeUnit={onChangeUnit}
                  quantity={quantityYield3}
                  error=""
                  onChangeQuantity={onChangeQuantity}
                  onChangeIsAuto={onChangeIsAuto}
                  isAuto={yieldIsAuto3} />
              </div> : null}
          </div>
          <div style={{ ...styles.dividerText, backgroundColor: globalColor }}>{t('tabComposition')}</div>
          <div style={{ ...styles.divider, backgroundColor: globalColor }}></div>
          <div style={styles.buttonContainer}>
            <ButtonFull text={t('btnAddIngredient')} color={globalColor} onButtonClick={() => onAddIngredient()} />
          </div>
          <table style={styles.table}>
            <tbody>
              <tr key="header">
                <th style={{ ...styles.headerCell, width: 100 }}>{t('lblQuantity')}</th>
                <th style={{ ...styles.headerCell, width: 50 }}>{t('lblUnit')}</th>
                <th style={{ ...styles.headerCell, width: 420 }}>{t('lblName')}</th>
                <th style={{ ...styles.headerCell, width: 100 }}>{t('lblAssociation')}</th>
                <th style={{}}></th>
              </tr>
            </tbody>
          </table>
        </div>
        {!isReady ?
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ReactLoading type="spin" color={colors.main} height={40} width={40} />
          </div> : renderItemList(ingredients)
        }
        <div ref={scrollBotRef} />
      </div>
    </div>
  );
};

const styles = {
  topContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    position: 'sticky',
    top: 0,
    backgroundColor: colors.background,
    zIndex: 50
  },
  topButtonContainer: {
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  topTitleContainer: {
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column'
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  name1: {
    fontSize: 20,
    fontWeight: 700
  },
  name2: {
    marginRight: 4,
    fontSize: 16,
    fontWeight: 700
  },
  topYieldContainer: {
    //alignSelf: 'flex-end',
    display: 'flex',
    flexDirection: 'column'
  },
  yieldRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 6,
  },
  yieldBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  yieldLabel: {
    marginRight: 20,
    width: 50,
    color: colors.text,
  },
  yieldInput: {
    pb: 0,
    width: 50,
    color: colors.text
  },
  yieldUnit: {
    marginLeft: 24,
    width: 50,
    fontSize: 15,
    fontWeight: 600,
    color: colors.darkGray,
    textAlign: 'center',
    backgroundColor: colors.grey,
    borderRadius: 6,
    borderStyle: 'solid',
    borderColor: colors.darkGray,
    borderWidth: 1,
  },
  dividerText: {
    marginTop: -20,
    alignSelf: 'center',
    padding: '10px 15px 6px 15px',
    fontWeight: 600,
    color: colors.textNegative,
    borderRadius: '10px 10px 0 0'
  },
  divider: {
    height: 6,
    marginBottom: 20
  },

  buttonContainer: {
    paddingBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse'
  },
  row: {
    backgroundColor: colors.semiLightGrey
  },
  headerCell: {
    padding: '0 20px',
    fontWeight: 400,
    textAlign: 'left',
  },
  cell: {
    padding: '10px 20px',
    fontSize: 16,
    fontWeight: '500',
  },
  firstCell: {
    width: 6,
  },
  gap: {
    width: 6,
    backgroundColor: colors.background,
  },
  lastCell: {
    width: 80,
    textAlign: 'center',
    backgroundColor: colors.background
  },

  modalWindow: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100
  },
  modalDiv: {
    width: 200,
    padding: '30px 40px',
    borderRadius: 12,
    borderStyle: 'solid',
    borderColor: colors.main,
    borderWidth: 2,
    backgroundColor: colors.background,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  modalText: {
    marginBottom: 20,
    textAlign: 'center'
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'space-around'
  },
};

export default IngredientScreen;