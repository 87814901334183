import { colors } from '../util/values';

export const commonStyles = {
  container: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "stretch",
    backgroundColor: colors.white
  },
  title: {
    margin: '20px 0 0 40px',
    fontSize: 40,
    fontWeight: 700,
    color: colors.main,
  },
  body: {
    margin: '0 40px 40px 40px',
    width: '100%',
    maxWidth: 1000,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "stretch",
    alignSelf: 'center'
  }
};