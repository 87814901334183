import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import ReactLoading from 'react-loading';
import i18n from '../i18n';
import { readFile, utils } from 'xlsx';
//context
import UserContext from '../context/UserContext';
//api
import aws from '../api/aws';
//components
import { ButtonFull } from '../components/Buttons';
//util
import { colors } from '../util/values';
import { commonStyles } from '../util/styles';
import { getCount, hierarchyToQuery } from '../util/climatecook';

const NewRecipeScreen = () => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [title, setTitle] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isReady, setIsReady] = useState(true);
  const [error, setError] = useState(false);
  const [errorArray1, setErrorArray1] = useState([]);
  const [errorArray2, setErrorArray2] = useState([]);
  const [errorArray3, setErrorArray3] = useState([]);
  const [errorArray4, setErrorArray4] = useState([]);
  const [errorArray5, setErrorArray5] = useState([]);
  const [errorArray6, setErrorArray6] = useState([]);
  const [errorArray7, setErrorArray7] = useState([]);
  const [errorArray8, setErrorArray8] = useState([]);
  const [hover, setHover] = useState();

  useEffect(() => {
    let nav = JSON.parse(localStorage.getItem("nav"));
    setTitle(nav != null ? nav[i18n.language].recipes : t('routeMyRecipes'));
  }, []);

  const handleMouseEnter = (which) => {
    setHover(which);
  };

  const handleMouseLeave = () => {
    setHover("");
  };

  const newIngredientManually = () => {
    let ingredient = [{
      ing_level: 0,
      main_ingredient_id: null,
      ingredient_id: null,
      unit: "gr",
      quantity: 0,
      name_fra: "",
      name_eng: "",
      main_unit: "kg",
      owner: user[0].user_id,
      density: 1,
      unit_single: 1,
      unit_mass: 1,
      unit_volume: 1,
      unit_liquid: 1,
      unitWeight: 1,
      yield_kg: null,
      yield_l: null,
      yield_unit: null,
      food_group: null,
      menu1: null,
      menu2: null,
      menu3: null,
      ern_ingredient_code: null,
      partner_ingredient_code: null,
      co2: 0,
      region: null,
      alternative: null,
      altErnIngCode: null,
      altPatnerIngCode: null,
      altCo2: null,
      altRegion: null,
      portions: 0,
      image_url: null,
      cook_time: null,
      is_recipe: 1,
      recipeCo2: null,
      uniqueId: getCount()
    }];

    navigate("/recipes/recipe", { state: { ingredient, categories: location.state.categories, menus: location.state.menus, isNew: true } });
  };

  const onImport = async (url) => {
    if (url != null) {
      try {
        setIsReady(false);
        const file = await (await fetch(URL.createObjectURL(url))).arrayBuffer();
        const workbook2 = readFile(file);
        const first_sheet2 = workbook2.Sheets[workbook2.SheetNames[0]];
        const rows = utils.sheet_to_json(first_sheet2, { header: ["name", "category", "menus", "isRecipe", "yieldKg", "yieldL", "yieldUnit", "portions", "ingName", "quantity", "unit"] });

        if (isNaN(rows[0].quantity)) {
          rows.splice(0, 1);
        }

        //let response = await hierarchyToQuery(user[0].user_id, rows);
        let response = await aws.post('/importRecipes', { owner: user[0].user_id, rows: rows, lang: i18n.language });
        if (response.data && response.data.error === 1) {
          setError(true);
          setErrorArray1(response.data.errorIngredients ? response.data.errorIngredients : []);
          setErrorArray2(response.data.errorRecipes ? response.data.errorRecipes : []);
          setErrorArray3(response.data.errorComposition ? response.data.errorComposition : []);
          setErrorArray4(response.data.errorIsRecipe ? response.data.errorIsRecipe : []);
          setErrorArray5(response.data.errorPortions ? response.data.errorPortions : []);
          setErrorArray6(response.data.errorYields ? response.data.errorYields : []);
          setErrorArray7(response.data.errorCategories ? response.data.errorCategories : []);
          setErrorArray8(response.data.errorMenus ? response.data.errorMenus : []);
          setShowModal(true);
        } else if (response.data && response.data.error === 0) {
          setError(false);
          setErrorArray1([]);
          setErrorArray2([]);
          setErrorArray3([]);
          setErrorArray4([]);
          setErrorArray5([]);
          setErrorArray6([]);
          setErrorArray7([]);
          setErrorArray8([]);
          setShowModal(true);
        }
        document.getElementById("uploadRecipesButton").value = "";
        setIsReady(true);
      } catch (e) {
        setIsReady(true);
      }
    }
  };

  const downloadModel = () => {
    setIsReady(false);
    fetch('https://backend-dev.emissionsreductionnow.com/xlsx/importModel.xlsx')
      .then(response => {
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = "Modèle d'importation recettes et sous-recettes 202401.xlsx";
          a.click();
          setIsReady(true);
        });
      });
  };

  const renderModal = () => {
    return (
      <div style={styles.modalWindow}>
        <div style={styles.modalDiv}>
          {!error ? <>
            <div style={styles.modalText}>{t('lblImportSuccess')}</div>
          </> : null}
          {errorArray1.length > 0 ? <>
            <div style={styles.modalText}>{t('lblMissingIngredients')}</div>
            <div style={styles.modalTextMonths}>
              {errorArray1.map(i => <div key={i}>{i}</div>)}
            </div>
          </> : null}
          {errorArray2.length > 0 ? <>
            <div style={styles.modalText}>{t('lblDuplicateRecipes')}</div>
            <div style={styles.modalTextMonths}>
              {errorArray2.map(i => <div key={i}>{i}</div>)}
            </div>
          </> : null}
          {errorArray3.length > 0 ? <>
            <div style={styles.modalText}>{t('lblWrongQuantities')}</div>
            <div style={styles.modalTextMonths}>
              {errorArray3.map(i => <div key={i}>{i}</div>)}
            </div>
          </> : null}
          {errorArray4.length > 0 ? <>
            <div style={styles.modalText}>{t('lblMissingIsRecipe')}</div>
            <div style={styles.modalTextMonths}>
              {errorArray4.map(i => <div key={i}>{i}</div>)}
            </div>
          </> : null}
          {errorArray5.length > 0 ? <>
            <div style={styles.modalText}>{t('lblWrongPortions')}</div>
            <div style={styles.modalTextMonths}>
              {errorArray5.map(i => <div key={i}>{i}</div>)}
            </div>
          </> : null}
          {errorArray6.length > 0 ? <>
            <div style={styles.modalText}>{t('lblWrongYields')}</div>
            <div style={styles.modalTextMonths}>
              {errorArray6.map(i => <div key={i}>{i}</div>)}
            </div>
          </> : null}
          {errorArray7.length > 0 ? <>
            <div style={styles.modalText}>{t('lblWrongCategories')}</div>
            <div style={styles.modalTextMonths}>
              {errorArray7.map(i => <div key={i}>{i}</div>)}
            </div>
          </> : null}
          {errorArray8.length > 0 ? <>
            <div style={styles.modalText}>{t('lblWrongMenus')}</div>
            <div style={styles.modalTextMonths}>
              {errorArray8.map(i => <div key={i}>{i}</div>)}
            </div>
          </> : null}
          <div style={styles.modalButtons}>
            <ButtonFull text={t('btnOk')} onButtonClick={() => setShowModal(false)} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div style={commonStyles.container}>
      {isReady ? null : <div style={styles.modalWindow}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ReactLoading type="spin" color={colors.main} height={40} width={40} />
        </div>
      </div>}
      {showModal ? renderModal() : null}
      <div style={commonStyles.title}>{title}</div>
      <div style={commonStyles.body}>
        {/*<div style={styles.option}>
          <div style={styles.title}>{t('lblLang')}</div>
          <div style={styles.buttons}>
            <div>English</div>
            <div>Français</div>
          </div>
        </div>*/}
        <div style={styles.option}>
          <div style={styles.title}>{t('lblAddRecipe')}</div>
          <div style={styles.buttons}>
            {/*<ButtonFull text={t('btnImportFile')} onButtonClick={() => { }} />
            <ButtonFull text={t('btnImportAPI')} onButtonClick={() => { }} />
      <ButtonFull text={t('btnDownloadLink')} onButtonClick={() => { }} />*/}
            <ButtonFull text={t('btnManually')} onButtonClick={() => newIngredientManually()} />
          </div>
        </div>
        <div style={styles.option}>
          <div style={styles.title}>{t('lblImportAll')}</div>
          <div style={styles.buttons}>
            <input id="uploadRecipesButton" type="file" accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={(event) => { onImport(event.target.files[0]) }} hidden />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <ButtonFull text={t('btnImportFile')} onButtonClick={() => document.getElementById("uploadRecipesButton").click()} />
              <a style={hover === "1" ? styles.linkHover : styles.link} onMouseEnter={() => handleMouseEnter("1")} onMouseLeave={() => handleMouseLeave("")} onClick={() => downloadModel()}>{t('lblImportModel')}</a>
            </div>
            {/*<ButtonFull text={t('btnImportAPI')} onButtonClick={() => { }} />*/}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  option: {
    margin: '20px 20px 40px 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  title: {
    marginBottom: 20,
    color: colors.text,
    fontSize: 30,
    fontWeight: 400,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20
  },
  modalWindow: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100
  },
  modalDiv: {
    width: 350,
    padding: '30px 40px',
    borderRadius: 12,
    borderStyle: 'solid',
    borderColor: colors.main,
    borderWidth: 2,
    backgroundColor: colors.background,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  modalText: {
    marginBottom: 0,
    textAlign: 'center'
  },
  modalTextMonths: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
    textAlign: 'center',
    color: colors.bad
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  link: {
    marginTop: 10,
    color: colors.link,
    textDecoration: 'none',
    cursor: 'pointer'
  },
  linkHover: {
    marginTop: 10,
    color: colors.grey,
    textDecoration: 'none',
    cursor: 'pointer'
  },
};

export default NewRecipeScreen;